#root {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

a:hover {
  cursor: pointer;
}
