/* Titles */
$fontSizeH1Mobile: 2rem;
$fontSizeH2Mobile: 1.375rem;
$fontSizeH3Mobile: 1.25rem;
$fontSizeH4Mobile: 1.25rem;
$fontSizeH5Mobile: 1rem;

$lineSpacingH1Mobile: 125%;
$lineSpacingH2Mobile: 140%;
$lineSpacingH3Mobile: 140%;
$lineSpacingH4Mobile: 150%;

$letterSpacingH5Mobile: 0.1rem;

/* Body font */
$fontSizeLabelMobile: 1rem;
$fontSizeBodyMobile: 1rem;
$fontSizeProgressStepperMobile: 0.75rem;
$fontSizeBodyCopyMobile: 1rem;
$fontSizeBodyCopyLinkMobile: 1rem;
$fontSizeBodyCopySmallMobile: 0.875rem;
$fontSizeFooterMobile: 0.875rem;
$fontSizeBodyCopyXSmallMobile: 0.75rem;

$lineSpacingBodyCopyMobile: 150%;
$lineSpacingBodyCopyLinkMobile: $lineSpacingBodyCopy;
$lineSpacingBodyCopySmallMobile: 150%;
$lineSpacingFooterMobile: 150%;
$lineSpacingBodyCopyXSmallMobile: 150%;

$letterSpacingProgressStepperMobile: 0.05em;
