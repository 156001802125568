body.menu-open {
  overflow: hidden !important;
  @media (min-width: $mobile) {
    overflow: auto !important;
  }
}

.animation-menu-open {
  animation: openMenu 0.5s ease-in-out;
  width: 60% !important;
}

.animation-menu-open-mobile {
  animation: openMenuMobile 0.5s ease-in-out;
  width: 70% !important;
}

.animation-menu-close-mobile {
  animation: closeMenuMobile 0.5s ease-in-out;
}

.animation-menu-close {
  animation: closeMenu 0.5s ease-in-out;
}

.animation-menu-open > div {
  animation: openMenuBody 0.5s ease-in-out;
}

.animation-menu-close > div {
  animation: closeMenuBody 0.5s ease-in-out;
}

@keyframes openMenu {
  0% {
    transform: translateX(-100%);
    width: 0%;
  }
  100% {
    transform: translateX(0%);
    width: 100%;
  }
}
@keyframes openMenuMobile {
  0% {
    transform: translateX(70%);
    width: 0%;
  }
  100% {
    transform: translateX(0%);
    width: 70%;
  }
}

@keyframes openMenuBody {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes closeMenu {
  0% {
    transform: translateX(0%);
    width: 80%;
  }
  100% {
    transform: translateX(-100%);
    width: 0%;
  }
}

@keyframes closeMenuMobile {
  0% {
    transform: translateX(0%);
    width: 70%;
  }
  100% {
    transform: translateX(70%);
    width: 0%;
  }
}

@keyframes closeMenuBody {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.rotate-class:hover {
  animation: rotate 1s ease-in-out;
}

.zoomMenu-class:hover {
  animation: zoomMenu 1s ease-out;
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes zoomMenu {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

/* Divider */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(-97%);
  }
}

@keyframes marqueeTwo {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(-97%);
  }
}

@keyframes moveRubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

/* Header */

@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes pulseImage {
  0% {
    -webkit-transform: scaleX(1), translate(-50%, -50%);
    transform: scaleX(1), translate(-50%, -50%);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05), translate(-50%, -50%);
    transform: scale3d(1.05, 1.05, 1.05), translate(-50%, -50%);
  }
  100% {
    -webkit-transform: scaleX(1), translate(-50%, -50%);
    transform: scaleX(1), translate(-50%, -50%);
  }
}

/* Shake */

@keyframes shakeX {
  0%,
  100% {
    -webkit-transform: translateZ(0) rotate(-9deg);
    transform: translateZ(0) rotate(-9deg);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0) rotate(-9deg);
    transform: translate3d(-10px, 0, 0) rotate(-9deg);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0) rotate(-9deg);
    transform: translate3d(10px, 0, 0) rotate(-9deg);
  }
}

@keyframes fadeoutfadeinV {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeoutfadeinTeam {
  0% {
    opacity: 0;
  }
  65% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes lightSpeedInRightShake {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg) rotate(-9deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg) rotate(-9deg);
  }
  20% {
    opacity: 1;
    -webkit-transform: skewX(20deg) rotate(-9deg);
    transform: skewX(20deg) rotate(-9deg);
  }
  40% {
    -webkit-transform: skewX(-5deg) rotate(-9deg);
    transform: skewX(-5deg) rotate(-9deg);
  }
  60% {
    -webkit-transform: translateZ(0) rotate(-9deg);
    transform: translateZ(0) rotate(-9deg);
  }
  60%,
  70%,
  75%,
  80%,
  85% {
    -webkit-transform: translate3d(-10px, 0, 0) rotate(-9deg);
    transform: translate3d(-10px, 0, 0) rotate(-9deg);
  }
  68%,
  78%,
  88%,
  99% {
    -webkit-transform: translate3d(10px, 0, 0) rotate(-9deg);
    transform: translate3d(10px, 0, 0) rotate(-9deg);
  }
}

@keyframes lightSpeedInLeftShake {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg) rotate(-9deg);
    transform: translate3d(-100%, 0, 0) skewX(30deg) rotate(-9deg);
  }
  20% {
    opacity: 1;
    -webkit-transform: skewX(-20deg) rotate(-9deg);
    transform: skewX(-20deg) rotate(-9deg);
  }
  40% {
    -webkit-transform: skewX(5deg) rotate(-9deg);
    transform: skewX(5deg) rotate(-9deg);
  }
  60% {
    -webkit-transform: translateZ(0) rotate(-9deg);
    transform: translateZ(0) rotate(-9deg);
  }
  60%,
  70%,
  75%,
  80%,
  85% {
    -webkit-transform: translate3d(-10px, 0, 0) rotate(-9deg);
    transform: translate3d(-10px, 0, 0) rotate(-9deg);
  }
  68%,
  78%,
  88%,
  99% {
    -webkit-transform: translate3d(10px, 0, 0) rotate(-9deg);
    transform: translate3d(10px, 0, 0) rotate(-9deg);
  }
}

@keyframes lightSpeedInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
  }
  40% {
    opacity: 1;
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg) rotate(-9deg);
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes lightSpeedInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
    transform: translate3d(-100%, 0, 0) skewX(30deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
  }
  80% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg);
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@keyframes gradients {
  0% {
    background: linear-gradient(
      0deg,
      rgba(255, 198, 0, 1) 0%,
      rgba(233, 170, 0, 1) 40%,
      rgba(255, 236, 120, 1) 59%,
      rgba(255, 236, 120, 1) 100%
    );
    background-size: 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px #630a0780);
  }

  25% {
    background: linear-gradient(
      180deg,
      rgba(255, 198, 0, 1) 0%,
      rgba(233, 170, 0, 1) 40%,
      rgba(255, 236, 120, 1) 59%,
      rgba(255, 236, 120, 1) 100%
    );
    background-size: 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px #630a0780);
  }

  50% {
    background: linear-gradient(
      0deg,
      rgba(255, 198, 0, 1) 0%,
      rgba(233, 170, 0, 1) 40%,
      rgba(255, 236, 120, 1) 59%,
      rgba(255, 236, 120, 1) 100%
    );
    background-size: 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px #630a0780);
  }

  75% {
    background: linear-gradient(
      180deg,
      rgba(255, 198, 0, 1) 0%,
      rgba(233, 170, 0, 1) 40%,
      rgba(255, 236, 120, 1) 59%,
      rgba(255, 236, 120, 1) 100%
    );
    background-size: 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px #630a0780);
  }

  100% {
    background: linear-gradient(
      0deg,
      rgba(255, 198, 0, 1) 0%,
      rgba(233, 170, 0, 1) 40%,
      rgba(255, 236, 120, 1) 59%,
      rgba(255, 236, 120, 1) 100%
    );
    background-size: 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px #630a0780);
  }
}

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes zoom {
  0% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(1);
  }
}

.animationZoomImage {
  animation: zoomPlus 2s ease-out;
}

@keyframes zoomPlus {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1.1);
  }
}

.animationCandy {
  animation: candyRain 7s infinite ease-in-out;
  opacity: 0.9;
}

$elements: 42;
@for $i from 0 to $elements {
  .animationCandy:nth-child(#{$i * 2}) {
    animation-delay: $i * 1.1s + 2s !important;
    right: 6%;
  }
}

$elementsTwo: 42;
@for $i from 0 to $elementsTwo {
  .animationCandy:nth-child(#{($i * 2) + 1}) {
    animation-delay: $i * 1.1s + 0s !important;
    left: 10%;
  }
}

@keyframes candyRain {
  0% {
    top: 0%;
    left: -10%;
  }

  20% {
    top: 90%;
    left: 5%;
  }

  40% {
    top: 0%;
    left: 40%;
  }

  60% {
    top: 80%;
    left: 70%;
  }

  80% {
    top: 0%;
    left: 90%;
  }

  100% {
    top: 90%;
    left: 110%;
  }
}

@keyframes headerRightAnimation {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    bottom: 30px;
    right: 35%;
    transform: scale(1.05);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    bottom: 20px;
    right: 35%;
    transform: scale(1);
  }
}

@keyframes headerLeftAnimation {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    bottom: -3%;
    left: 0px;
    transform: scale(1);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    bottom: -10%;
    left: 0px;
    transform: scale(1);
  }
}

.waves {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    min-width: 307vw;
    min-height: 285vw;
    background-color: #ffd22c;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &:before {
    bottom: 15vh;
    border-radius: 45%;
    animation-duration: 10s;
  }

  &:after {
    bottom: 12vh;
    opacity: 0.5;
    border-radius: 47%;
    animation-duration: 10s;

    bottom: 7vh;
    opacity: 0.5;
    border-radius: 50%;
    animation-duration: 10s;
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, 0) rotateZ(0deg);
  }
  50% {
    transform: translate(-50%, -2%) rotateZ(180deg);
  }
  100% {
    transform: translate(-50%, 0%) rotateZ(360deg);
  }
}

@keyframes shine {
  0% {
    transform: scale(1);
    filter: brightness(3);
  }
  100% {
    transform: scale(2.5);
    filter: brightness(1);
  }
}

@keyframes shineSoft {
  0% {
    transform: scale(1);
    filter: brightness(1.3);
  }
  100% {
    filter: brightness(0.9);
  }
}

@keyframes logoshadow {
  50% {
    filter: drop-shadow(2px 2px #fbea76);
    transform: translate(-50%, -50%) scale(1.1);
  }
}

@keyframes shineStar1 {
  0% {
    transform: scale(1);
    filter: brightness(1.3);
  }
  50% {
    filter: brightness(0.9);
    transform: translate(0px, 10px) scale(1.3);
  }
  100% {
    transform: scale(1);
    filter: brightness(1.3);
  }
}

@keyframes shineStar2 {
  0% {
    transform: scale(1);
    filter: brightness(1.3);
  }
  50% {
    filter: brightness(0.9);
    transform: translate(-10px, -10px) scale(1.3);
  }
  100% {
    transform: scale(1);
    filter: brightness(1.3);
  }
}

@keyframes shineStar3 {
  0% {
    transform: scale(1);
    filter: brightness(1.3);
  }
  50% {
    filter: brightness(0.9);
    transform: translate(10px, 10px) scale(1.3);
  }
  100% {
    transform: scale(1);
    filter: brightness(1.3);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes containerStar1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-20deg);
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
  animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.tilt-in-fwd-tr {
  -webkit-animation: tilt-in-fwd-tr 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: tilt-in-fwd-tr 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
    transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
    transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-ver-center
 * ----------------------------------------
 */

.scale-up-ver-center {
  -webkit-animation: scale-up-ver-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-ver-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-ver-center {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes scale-up-ver-center {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
