/* Primary */
$deepSea: #12256d;
$blueprint: #005490;
$capri: #009bd7;
$flame: #ed7119;

/* Secondary */
$seafoam: #b3e1dc;
$marigold: #ffcc49;
$trophy: #da9100;
$raspberry: #df1b46;

/* Grays */
$wallStreet: #2d3841;
$steel: #747c82;
$mist: #d1d3d5;
$cloud: #f7f7f7;
$white: #ffffff;

/* Black */
$midnight: #12161a;

/* Others */
$sage: #009b89;
$evergreen: #006c60;
$mulberry: #9c1331;

/* Highlight */
$lightAqua: #e5f5fb;

/* Buttons */
$button-orange-focus: #cc671e;
